import React, { useState } from 'react'
import Aux from '../hoc/index'
import Header from '../components/Header'
import greydivider from '../../templates/images/icons/icon-devider-gray.png'
import divider1 from '../../templates/images/icons/divider_1.png'
import Footer from '../components/Footer'

const Contact = (props) => {
    const [current, setCurrent] = useState('1');
    return(
        <Aux>
             <div className="page-wrapper">
                 <Header current="contact" />
                 <section class="page-title cfc-contact-pageheader">
        <div class="auto-container">
            <h1>Contacts</h1>
            <ul class="page-breadcrumb">
                <li><a href="/">Home</a></li>
                <li>Contacts</li>
            </ul>
        </div>
    </section>

    <section class="contact-section">
        <div class="auto-container">
            <div class="sec-title text-center">
                <div class="divider"><img src={divider1} alt="" /></div>
                <h2>Contact Us</h2>
                
            </div>

            
            {/* <div class="row clearfix">

                <div class="column col-lg-12 col-md-12 col-sm-12">
                    <div class="inner-column">
                        <div class="title">
                            <div class="icon"><img src={greydivider} alt="" /></div>
                            <h4>Send Message</h4>
                        </div>
                        <div class="contact-form">
                            <form action="#" method="post" id="email-form">

                                <div class="form-group">
                                    <div class="response"></div>
                                </div>

                                <div class="form-group">
                                    <input type="text" name="username" class="username" placeholder="Your Name *" />
                                </div>

                                <div class="form-group">
                                    <input type="email" name="email" class="email" placeholder="Your Email *" />
                                </div>
                                
                                <div class="form-group">
                                    <textarea name="contact_message" placeholder="Text Message"></textarea>
                                </div>
                                
                                <div class="form-group">
                                    <button class="theme-btn" type="button" id="submit" name="submit-form">Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
            </div> */}

            <div className ="row clearfix">
            <div class="column col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-column">
                        <div class="title">
                            <div class="icon"><img src={greydivider} alt="" /></div>
                            <h4>Our Contacts</h4>
                        </div>

                        <ul class="contact-info">
                            
                            <li><a href="tel:918973323232">+91 89733 23232</a><br /><a href="tel:918675323232">+91 86753 23232</a></li>
                            <li><a href="mailto:orders@callforcake.com">orders@callforcake.com</a></li>
                        </ul>
                    </div>
                </div>
                <div class="column col-lg-6 col-md-6 col-sm-12 order-3">
                    <div class="inner-column">
                        <div class="title">
                            <div class="icon"><img src={greydivider} alt="" /></div>
                            <h4>Our Branches</h4>
                        </div>

                        
                    <ul class="accordion-box">
                        
                        <li class={current==="1"?"accordion block active-block":"accordion block"}>
                            <div onClick={()=>{setCurrent("1")}} class={current==="1"?"acc-btn active":"acc-btn"}><div class="icon-outer"><span class="icon fa fa-plus"></span> </div>Sivananda Colony</div>
                            <div class={current==="1"?"acc-content current":"acc-content"}>
                                <div class="content">
                                    <div class="text">#63-1, Rajunaidu Street, Tatabad,<br />Sivananda Colony - 641012.<br />P: 0422-4376161</div>
                                </div>
                            </div>
                        </li>
                        <li class={current==="2"?"accordion block active-block":"accordion block"}>
                            <div onClick={()=>{setCurrent("2")}} class={current==="2"?"acc-btn active":"acc-btn"}><div class="icon-outer"><span class="icon fa fa-plus"></span> </div>GN Mills</div>
                            <div class={current==="2"?"acc-content current":"acc-content"}>
                                <div class="content">
                                    <div class="text">SHOP A, SHOP B, #3,<br />VCS Nagar 2nd Street, MTP Road,<br />GN Mills - 641029.<br />P: 0422-4976263 / 4376165</div>
                                </div>
                            </div>
                        </li>
                        <li class={current==="3"?"accordion block active-block":"accordion block"}>
                            <div onClick={()=>{setCurrent("3")}} class={current==="3"?"acc-btn active":"acc-btn"}><div class="icon-outer"><span class="icon fa fa-plus"></span> </div>Ganapathy</div>
                            <div class={current==="3"?"acc-content current":"acc-content"}>
                                <div class="content">
                                    <div class="text">Amman complex,<br />Bharathi Nagar, Gandhipuram,<br />Coimbatore - 641006.<br />P: 0422-4391661</div>
                                </div>
                            </div>
                        </li>
                        <li class={current==="4"?"accordion block active-block":"accordion block"}>
                            <div onClick={()=>{setCurrent("4")}} class={current==="4"?"acc-btn active":"acc-btn"}><div class="icon-outer"><span class="icon fa fa-plus"></span> </div>Thudiyalur</div>
                            <div class={current==="4"?"acc-content current":"acc-content"}>
                                <div class="content">
                                    <div class="text">Sree Saravana Complex,<br />#04/G2, MTP Road,<br />Thudiyalur - 641034.<br />P: 0422-4204640</div>
                                </div>
                            </div>
                        </li>
                        <li class={current==="5"?"accordion block active-block":"accordion block"}>
                            <div onClick={()=>{setCurrent("5")}} class={current==="5"?"acc-btn active":"acc-btn"}><div class="icon-outer"><span class="icon fa fa-plus"></span> </div>NSR Road, Saibaba Colony</div>
                            <div class={current==="5"?"acc-content current":"acc-content"}>
                                <div class="content">
                                    <div class="text">339, NSR Road,<br />Saibaba Colony,<br />Coimbatore. - 641011<br />P: 0422-4372830</div>
                                </div>
                            </div>
                        </li>

                        
                       
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
             </div>
            
        </Aux>
    )
}

export default Contact