import React, { useEffect, useState } from 'react';
import Aux from '../hoc';
const ShopEntry = (props) => {

    

    useEffect(()=>{
        if(props.location.state!==undefined){
            props.history.push("/shop",{catgId :props.location.state.catgId});
          }else{
            props.history.push("/shop",{catgId :1});
          }
    },[])

    return(
        <Aux>
            Shop Entry
        </Aux>
    )
}

export default ShopEntry;