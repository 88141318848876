import React from 'react';
import logo from './logo.svg';
import './App.css';
import HomeComponent from './c4cake/Home/Home';
import ContactComponent from './c4cake/Contact/Contact';
import ShopComponent from './c4cake/Shop/Shop';
import ShopEntryComponent from './c4cake/Shop/ShopEntry';
import {Route, Switch} from 'react-router-dom';
import './templates/css/bootstrap.css';
import './templates/plugins/revolution/css/settings.css';
import './templates/plugins/revolution/css/layers.css';
import './templates/plugins/revolution/css/navigation.css';

import './templates/css/style.css'
import './templates/css/responsive.css'



function App() {
  return (
    <Switch>
      <Route path="/" exact component={HomeComponent} />
      <Route path="/contact" exact component={ContactComponent} />
      <Route path="/shop" exact component={ShopComponent} />
      <Route path="/shop-entry" exact component={ShopEntryComponent} />
    </Switch>
  );
}

export default App;
