import React from 'react'
import smalllogo from '../../templates/images/logo-small.png'
import MenuList from './MenuList'

const StickyMenu = (props) => {
    return(
        <div className="sticky-header">
       
        <div className="auto-container clearfix">
      
        <div className="logo">
            <a href="#" title="Sticky Logo"><img src={smalllogo} alt="Sticky Logo" /></a>
        </div>

      
        <div className="nav-outer">
           
            <nav className="main-menu">
                <div className="collapse navbar-collapse clearfix show" id="navbarSupportedContent">
                    <ul className="navigation menu-left clearfix">
                        <li className={props.current==='home'?"current":""}><a href="/">Home</a>
                           </li>
                        <li className={props.current==='howitlooks'?"current":""}><a href="about-us.html">How it looks</a>
                            </li>
                       
                    </ul>

                    <ul className="navigation menu-right clearfix">
                        <li className={props.current==='menu'?"dropdown current":"dropdown"}><a href="/shop">Menu</a>
                            <ul>
                            <MenuList props={props} />
                                
                            </ul>
                        <div className="dropdown-btn"><span className="fa fa-plus"></span></div></li>
                        <li className={props.current==='contact'?"current":""}><a href="/contact">Contact</a>
                            </li>
                       
                    </ul>
                </div>
            </nav>
        </div>
    </div>

    </div>

    )
}

export default StickyMenu;