import React, {useState} from 'react';

import logoimg from '../../templates/images/logo.png'
import logosmallimg from '../../templates/images/logo-small.png'
import HeaderMenu from '../Menus/HeaderMenu'
import StickyMenu from '../Menus/StickyMenu'
import MobileMenu from '../Menus/MobileMenu'
const Header = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const toggleHandler = () => {
        setIsMenuOpen(!isMenuOpen);
        if(isMenuOpen){
            document.body.classList.add("mobile-menu-visible");
        }else{
            document.body.classList.remove("mobile-menu-visible");
        }
    }
    return(
        <header className="main-header">
   
    <div className="menu_wave"></div>

    
    <div className="main-box">
        <div className="menu-box">
            <div className="logo"><a href="/"><img src={logoimg} alt="" title="" /></a></div>
            <HeaderMenu current={props.current} props={props} />
        </div>
    </div>

    
   <StickyMenu current={props.current} props={props} />
   
    <div className="mobile-header">
        <div className="logo"><a href="/"><img src={logosmallimg} alt="" title="" /></a></div>

       
        <div className="nav-outer clearfix">
            <div className="mobile-nav-toggler" onClick={toggleHandler} ><span className="icon fa fa-bars"></span></div>
        </div>
    </div>

   
   <MobileMenu current={props.current} props={props} />

</header>
    )
}

export default Header;