import React from 'react'
import MenuList from './MenuList'

const HeaderMenu = (props) => {
    return(
        <div className="nav-outer clearfix">
             

        <nav className="main-menu navbar-expand-md navbar-light">
        <div className="collapse navbar-collapse clearfix" id="navbarSupportedContent">
            <ul className="navigation menu-left clearfix">
                <li className={props.current==='home'?"current":""}><a href="/">Home</a>
                    
                </li>
                <li className={props.current==='howitlooks'?"current":""} ><a href="/">How it looks</a>
                  
                </li>
               
            </ul>

            <ul className="navigation menu-right clearfix">
                <li className={props.current==='menu'?"dropdown current":"dropdown"}><a href="/shop">Menu</a>
                    <ul>
                       <MenuList props={props} />
                    </ul> <div className="dropdown-btn"><span className="fa fa-plus"></span></div>
                </li>
             
                <li className={props.current==='contact'?"current":""}><a href="/contact">Contact</a></li>
            </ul>
        </div>
    </nav>

    </div>
    )
}

export default HeaderMenu;