import React, { useEffect, useState } from "react";
import Aux from "../hoc";

import * as config from "../configuration/Config";

const MenuList = (props) => {
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    getMenuList();
    console.log(props);
  }, []);

  const getMenuList = () => {
    fetch(`${config.apiurl}/store/getCategories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        const resJson = response.json();
        resJson.then((json) => {
          setMenuList(json);
        });
      }
    });
  };

  return (
    <Aux>
      {menuList.map((menu) => {
        return (
          <li key={menu.value}>
            <a
             onClick={()=>{
                props.props.props.props.history.push("/shop-entry",{catgId:menu.value});
             }}
            >
              {menu.label}
            </a>
          </li>
        );
      })}
    </Aux>
  );
};

export default MenuList;
