import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Aux from "../hoc";
import "../../templates/css/select2.min.css";
import * as config from "../configuration/Config";
import NoImage from '../Images/no-photos.png';

const Shop = (props) => {
  const [productList, setProductList] = useState([]);
  useEffect(() => {
      if(props.location.state!==undefined){
        getProductsByCatgId(props.location.state.catgId);
        
      }else{
        getProductsByCatgId(1);
       
      }
      
    
  },[]);

  const getProductsByCatgId = (categId) => {
    fetch(`${config.apiurl}/store/getProductsByCategoryId/${categId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((resp) => {
      if (resp.ok) {
        const jsonData = resp.json();
        jsonData.then((json) => {
          setProductList(json);
        });
      }
    });
  };

  return (
    <Aux>
      <div classNameName="page-wrapper">
        <Header current="menu" props={props} />
        <section className="page-title cfc-menu-pageheader">
          <div className="auto-container">
            <h1>Menu</h1>
            <ul className="page-breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Menu</li>
            </ul>
          </div>
        </section>

        <div className="sidebar-page-container">
          <div className="auto-container">
            <div className="row clearfix">
              {/* <div className="sidebar-side sticky-container col-lg-3 col-md-12 col-sm-12">
                <aside className="sidebar theiaStickySidebar">
                  <div className="sticky-sidebar">
                    <div className="sidebar-widget search-widget">
                      <form method="post" action="contact.html">
                        <div className="form-group">
                          <input
                            type="search"
                            name="search-field"
                            value=""
                            placeholder="Search products…"
                            required
                          />
                          <button type="submit">
                            <span className="icon fa fa-search"></span>
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className="sidebar-widget rangeslider-widget">
                      <div className="widget-content">
                        <h3 className="widget-title">Price Filter</h3>

                        <div className="range-slider-one clearfix">
                          <div className="clearfix">
                            <div className="pull-left input-box">
                              <div className="title">Min Price:</div>
                              <div className="input"></div>
                            </div>
                            <div className="pull-right btn-box">
                              <select
                                name="orderby"
                                className="cfc-select-style"
                                style={{ width: "100%" }}
                              >
                                <option value="1">0</option>
                                <option value="2">50</option>
                                <option value="3">100</option>
                                <option value="4">200</option>
                                <option value="5">500</option>
                                <option value="6">1000</option>
                              </select>
                            </div>
                          </div>
                          <div
                            className="clearfix"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="pull-left input-box">
                              <div className="title">Max Price:</div>
                              <div className="input"></div>
                            </div>
                            <div className="pull-right btn-box">
                              <select
                                name="orderby"
                                className="cfc-select-style"
                                style={{ width: "100%" }}
                              >
                                <option value="1">0</option>
                                <option value="2">50</option>
                                <option value="3">100</option>
                                <option value="4">200</option>
                                <option value="5">500</option>
                                <option value="6">1000</option>
                              </select>
                            </div>
                          </div>
                          <div
                            className="pull-right btn-box"
                            style={{ marginTop: "15px" }}
                          >
                            <a href="#" className="theme-btn">
                              <span className="btn-title">Filtter</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div> */}
              <div className="content-side col-lg-12 col-md-12 col-sm-12">
                <div className="our-shop">
                  <div className="shop-upper-box clearfix">
                    <div className="items-label">
                      Showing all {productList.length} results
                    </div>
                    {/* <div className="orderby">
                      <select name="orderby" className="cfc-select-style">
                        <option value="popularity">Sort by popularity</option>
                        <option value="rating">Sort by average rating</option>
                        <option value="date">Sort by newness</option>
                        <option value="price">
                          Sort by price: low to high
                        </option>
                        <option value="price-desc">
                          Sort by price: high to low
                        </option>
                      </select>
                    </div> */}
                  </div>

                  <div className="row clearfix">
                    {productList.map((product) => {
                      return (
                        <div key={product.id} className="shop-item col-lg-4 col-md-6 col-sm-12">
                          <div className="inner-box">
                            <div className="image-box">
                              {product.available?<div className="sale-tag">sale!</div>:null} 
                              {/* <figure className="image">
                                <a >
                                    {product.imageData!==""? <img
                                    src={`data:${product.imageType};base64,${product.imageData}`} 
                                    alt=""
                                  />: <img
                                  src={NoImage} 
                                  alt=""
                                />}
                                 
                                </a>
                              </figure> */}
                             
                            </div>
                            <div className="lower-content">
                              <h4 className="name">
                                <a href="#">
                                  {product.productName}
                                </a>
                              </h4>
                              {/* <div className="rating">
                                {product.availableWeights}
                              </div> */}
                              <div className="price">
                                <del>{product.actualProductPrice?'₹'+product.actualProductPrice:''}</del> {product.finalProductPrice?'₹'+product.finalProductPrice:''}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default Shop;
