import React, { useEffect, useState } from 'react'
import MenuList from './MenuList';

const MobileMenu = (props) => {

    const [liked, setLiked] = useState(false);
    const [mobileSMenuActive, setMobileSMenuActive] = useState(false)
    const [mobileSubmenuActive, setMobileSubmenuActive] = useState('dropdown-btn')
    const [mobileMenuUL, setMobileMenuUL] = useState('cfc-display-none')

    useEffect(()=>{
        if(liked){
            document.body.classList.remove("mobile-menu-visible");
        }
    },[liked])

    const updateMenu = () => {  
        setLiked(false);
        if(!mobileSMenuActive){
            setMobileSubmenuActive('dropdown-btn active')
            setMobileMenuUL('cfc-display-block')
           
        }else{
            setMobileSubmenuActive('dropdown-btn')
            setMobileMenuUL('cfc-display-none')
        }

        setMobileSMenuActive(!mobileSMenuActive);
    }

    return(
        <div className="mobile-menu" >          
        <nav className="menu-box">
        <div className="nav-logo"><a href="/"><img src="images/logo-small.png" alt="" title="" /></a></div> 
        
    
                <div className="collapse navbar-collapse clearfix show" id="navbarSupportedContent">
                    <ul className="navigation menu-left clearfix">
                        <li className={props.current==='home'?"current":""}><a href="/">Home</a>
                            </li>
                        <li className={props.current==='howitlooks'?"current":""}><a href="about-us.html">How it looks</a>
                            </li>
                        
                    </ul>

                    <ul className="navigation menu-right clearfix">
                        <li className={props.current==='menu'?"dropdown current":"dropdown"}><a href="/shop">Menu</a>
                            <ul className={mobileMenuUL}>
                            <MenuList props={props} />
                                
                            </ul>
                        <div className={mobileSubmenuActive}><span onClick={updateMenu} className="fa fa-plus"></span></div></li>
                        
                        <li className={props.current==='contact'?"current":""}><a href="/contact">Contact</a></li>
                    </ul>
                </div>
                <div className="close-btn"><span onClick={() => {
                    setLiked(true)
                }} className="icon fa fa-times"></span></div>
            </nav>
            </div>
    )
}

export default MobileMenu;