import React from 'react'

import slider1 from '../../templates/images/main-slider/slider1.jpg'
import slider2 from '../../templates/images/main-slider/slider2.jpg'
import slider3 from '../../templates/images/main-slider/slider3.jpg'
import slider1bg from '../../templates/images/main-slider/slider_bg_1.png'
import slider2bg from '../../templates/images/main-slider/slider_bg_2.png'
import slider3bg from '../../templates/images/main-slider/slider_bg_3.png'
import slider4bg from '../../templates/images/main-slider/slider_bg_4.png'

const MainSlider = (props) => {
    return(
        <section className="main-slider">
        <div className="slider_wave"></div>
        <div className="rev_slider_wrapper fullwidthbanner-container"  id="rev_slider_one_wrapper" data-source="gallery">
            <div className="rev_slider fullwidthabanner" id="rev_slider_one" data-version="5.4.1">
                <ul>
                    <li data-index="rs-4" data-transition="zoomout" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="850"  data-thumb="#"  data-delay="5999"  data-rotate="0"  data-saveperformance="off"  data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">

                    <img src={slider1}  alt="" title="Home Cakes"  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />

                    <div className="tp-caption tp-shape tp-shapewrapper  tp-resizeme cfc-zindex-5" 
                        id="slide-4-layer-44" 
                        data-x="center" data-hoffset="1" 
                        data-y="center" data-voffset="-3" 
                        data-width="['full','full','full','full']"
                        data-height="['full','full','full','full']"
                        data-type="shape" 
                        data-basealign="slide" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        ></div>

                    
                    <div className="tp-caption   tp-resizeme cfc-zindex-6" 
                        id="slide-4-layer-40" 
                        data-x="center" data-hoffset="" 
                        data-y="center" data-voffset="" 
                        data-width="['none','none','none','none']"
                        data-height="['none','none','none','none']"
                        data-type="image" 
                        data-responsive_offset="on"
                        data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        ><img src={slider1bg} alt="" data-ww="654px" data-hh="654px" width="654" height="654" data-no-retina /> </div>

                
                    <div className="tp-caption   tp-resizeme cfc-zindex-7" 
                        id="slide-4-layer-33" 
                        data-x="center" data-hoffset="" 
                        data-y="center" data-voffset="100" 
                        data-width="['auto']"
                        data-height="['auto']"
                        data-visibility="['on','on','off','off']"
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
                        data-textAlign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        >We offer now a great range of different<br />flavoured bite-size pastries and cakes </div>

                  
                    <div className="tp-caption   tp-resizeme cfc-zindex-8" 
                        id="slide-4-layer-31" 
                        data-x="center" data-hoffset="" 
                        data-y="center" data-voffset="-18" 
                        data-width="['399']"
                        data-height="['auto']"
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
                        data-textAlign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        >Welcome <br />to Call for <br /> Cake </div>

               
                    <div className="tp-caption tp-resizeme cfc-zindex-9" 
                        id="slide-4-layer-41" 
                        data-x="center" data-hoffset="" 
                        data-y="center" data-voffset="-150" 
                        data-width="['none','none','none','none']"
                        data-height="['none','none','none','none']"
                        data-type="image" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                       ><img src={slider4bg} alt="" data-ww="125px" data-hh="60px" width="125" height="60" data-no-retina /> </div>

                  
                    <div className="tp-caption tp-resizeme hide-sm cfc-zindex-10" 
                        id="slide-4-layer-42" 
                        data-x="398" 
                        data-y="center" data-voffset="" 
                        data-width="['none','none','none','none']"
                        data-height="['none','none','none','none']"
                        data-type="image" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                       ><img src={slider3bg} alt="" data-ww="196px" data-hh="107px" width="196" height="107" data-no-retina /> </div>

                      
                        <div className="tp-caption tp-resizeme hide-sm cfc-zindex-11" 
                            id="slide-4-layer-43" 
                            data-x="1325" 
                            data-y="center" data-voffset="" 
                            data-width="['none','none','none','none']"
                            data-height="['none','none','none','none']"
                            data-type="image" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            ><img src={slider2bg} alt="" data-ww="196px" data-hh="107px" width="196" height="107" data-no-retina /></div>
                    </li>

                  
                    <li data-index="rs-5" data-transition="zoomout" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="850"  data-thumb=""  data-delay="5999"  data-rotate="0"  data-saveperformance="off"  data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                     
                        <img src={slider2}  alt="" title="Home Cakes"  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
                       
                        <div className="tp-caption tp-shape tp-shapewrapper  tp-resizeme cfc-zindex-5" 
                            id="slide-5-layer-44" 
                            data-x="center" data-hoffset="" 
                            data-y="center" data-voffset="" 
                            data-width="['full','full','full','full']"
                            data-height="['full','full','full','full']"
                            data-type="shape" 
                            data-basealign="slide" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            > </div>

                      
                        <div className="tp-caption   tp-resizeme cfc-zindex-6" 
                            id="slide-5-layer-40" 
                            data-x="center" data-hoffset="" 
                            data-y="center" data-voffset="-1" 
                            data-width="['none','none','none','none']"
                            data-height="['none','none','none','none']"
                            data-type="image" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                           ><img src={slider1bg} alt="" data-ww="654px" data-hh="654px" width="654" height="654" data-no-retina /> </div>

                    
                        <div className="tp-caption tp-resizeme cfc-zindex-7" 
                            id="slide-5-layer-33" 
                            data-x="center" data-hoffset="" 
                            data-y="center" data-voffset="99" 
                            data-width="['auto']"
                            data-height="['auto']"
                            data-visibility="['on','on','off','off']"
                            data-type="text" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
                            data-textAlign="['center','center','center','center']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            >We offer now a great range of different<br /> flavoured bite-size pastries and cakes </div>

                      
                        <div className="tp-caption   tp-resizeme cfc-zindex-8_1" 
                            id="slide-5-layer-31" 
                            data-x="center" data-hoffset="" 
                            data-y="center" data-voffset="-18" 
                            data-width="['441']"
                            data-height="['auto']"
                            data-type="text" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
                            data-textAlign="['center','center','center','center']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            >Welcome <br />to Call for <br /> Cake </div>

                       
                        <div className="tp-caption   tp-resizeme cfc-zindex-9" 
                            id="slide-5-layer-41" 
                            data-x="center" data-hoffset="" 
                            data-y="center" data-voffset="-150" 
                            data-width="['none','none','none','none']"
                            data-height="['none','none','none','none']"
                            data-type="image" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                           ><img src={slider4bg} alt="" data-ww="125px" data-hh="60px" width="125" height="60" data-no-retina /> </div>

                     
                        <div className="tp-caption tp-resizeme hide-sm cfc-zindex-10" 
                            id="slide-5-layer-42" 
                            data-x="398" 
                            data-y="center" data-voffset="" 
                            data-width="['none','none','none','none']"
                            data-height="['none','none','none','none']"
                            data-type="image" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                           ><img src={slider3bg} alt="" data-ww="196px" data-hh="107px" width="196" height="107" data-no-retina /> </div>

                      
                        <div className="tp-caption tp-resizeme hide-sm cfc-zindex-11" 
                            id="slide-5-layer-43" 
                            data-x="1325" 
                            data-y="center" data-voffset="" 
                            data-width="['none','none','none','none']"
                            data-height="['none','none','none','none']"
                            data-type="image" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            ><img src={slider2bg} alt="" data-ww="196px" data-hh="107px" width="196" height="107" data-no-retina /> </div>
                    </li>
                  
                    <li data-index="rs-6" data-transition="zoomout" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="850"  data-thumb=""  data-delay="5999"  data-rotate="0"  data-saveperformance="off"  data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                      
                        <img src={slider3}  alt="" title="Home Cakes"  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
                     
                        <div className="tp-caption tp-shape tp-shapewrapper  tp-resizeme cfc-zindex-5" 
                            id="slide-6-layer-44" 
                            data-x="center" data-hoffset="" 
                            data-y="center" data-voffset="" 
                            data-width="['full','full','full','full']"
                            data-height="['full','full','full','full']"
                            data-type="shape" 
                            data-basealign="slide" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            > </div>

                      
                        <div className="tp-caption   tp-resizeme cfc-zindex-6" 
                            id="slide-6-layer-40" 
                            data-x="center" data-hoffset="" 
                            data-y="center" data-voffset="-1" 
                            data-width="['none','none','none','none']"
                            data-height="['none','none','none','none']"
                            data-type="image" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            ><img src={slider1bg} alt="" data-ww="654px" data-hh="654px" width="654" height="654" data-no-retina /> </div>

                     
                        <div className="tp-caption   tp-resizeme cfc-zindex-7" 
                            id="slide-6-layer-33" 
                            data-x="center" data-hoffset="" 
                            data-y="center" data-voffset="100" 
                            data-width="['auto']"
                            data-height="['auto']"
                            data-visibility="['on','on','off','off']"
                            data-type="text" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
                            data-textAlign="['center','center','center','center']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            >We offer now a great range of different<br /> flavoured bite-size pastries and cakes </div>

                     
                        <div className="tp-caption   tp-resizeme cfc-zindex8_2" 
                            id="slide-6-layer-31" 
                            data-x="center" data-hoffset="" 
                            data-y="center" data-voffset="-18" 
                            data-width="['394']"
                            data-height="['auto']"
                            data-type="text" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
                            data-textAlign="['center','center','center','center']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            >Welcome <br />to Call for <br /> Cake </div>

                 
                        <div className="tp-caption   tp-resizeme cfc-zindex-9" 
                            id="slide-6-layer-41" 
                            data-x="center" data-hoffset="" 
                            data-y="center" data-voffset="-150" 
                            data-width="['none','none','none','none']"
                            data-height="['none','none','none','none']"
                            data-type="image" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            ><img src={slider4bg} alt="" data-ww="125px" data-hh="60px" width="125" height="60" data-no-retina /> </div>

                     
                        <div className="tp-caption tp-resizeme hide-sm cfc-zindex-10" 
                            id="slide-6-layer-42" 
                            data-x="398" 
                            data-y="center" data-voffset="" 
                            data-width="['none','none','none','none']"
                            data-height="['none','none','none','none']"
                            data-type="image" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            ><img src={slider3bg} alt="" data-ww="196px" data-hh="107px" width="196" height="107" data-no-retina /> </div>

                      
                        <div className="tp-caption tp-resizeme hide-sm cfc-zindex-11" 
                            id="slide-6-layer-43" 
                            data-x="1325" 
                            data-y="center" data-voffset="" 
                            data-width="['none','none','none','none']"
                            data-height="['none','none','none','none']"
                            data-type="image" 
                            data-responsive_offset="on" 
                            data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            ><img src={slider2bg} alt="" data-ww="196px" data-hh="107px" width="196" height="107" data-no-retina /> </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    )
}

export default MainSlider;