import React, {useState} from 'react'
import Aux from '../hoc/index'



import Header from '../components/Header'
import MainSlider from './MainSlider'
import Specialties from './Specialties'
import Testimonial from './Testimonial'
import Footer from '../components/Footer'
import Features from './Features'
import Portfolio from './Portfolio'
import CallToAction from './CallToAction'
const Home = (props) =>{
    
    return(
        <Aux>
            <div className="page-wrapper">

<Header current="home" props={props} />

<MainSlider />

<Specialties />

<CallToAction />

<Portfolio />

<Features />

<Testimonial />

<Footer />

</div>


        </Aux>
    )
}

export default Home;